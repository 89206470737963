/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'tippy.js/dist/tippy.css';

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.pointer {
    cursor: pointer;
}

.chapter {
    font-weight: bold;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
}

.appear {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.appear-delay {
    animation: fadeInDelay 1s;
    -webkit-animation: fadeInDelay 1s;
    -moz-animation: fadeInDelay 1s;
    -o-animation: fadeInDelay 1s;
    -ms-animation: fadeInDelay 1s;
}

@keyframes fadeInDelay {
    0% {opacity:0;}
    50% {opacity: 0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeInDelay {
    0% {opacity:0;}
    50% {opacity: 0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeInDelay {
    0% {opacity:0;}
    50% {opacity: 0;}
    100% {opacity:1;}
}

@-o-keyframes fadeInDelay {
    0% {opacity:0;}
    50% {opacity: 0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeInDelay {
    0% {opacity:0;}
    50% {opacity: 0;}
    100% {opacity:1;}
}

.tooltip {
    position: absolute;
    max-width: 90%;
    font-size: 14px;
    text-align: center;
    color: #f8f8f2;
    padding: 5px;
    background: #1e1e1f;
    z-index: 1000;
    opacity: 0;
}
  
.tooltip_show {
    opacity: 1;
}

.wider-alert {
    --min-width: 325px;
}

.fullscreen-modal::part(content) {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.ios .fullscreen-modal::part(content) {
    top: 26px;
}

.doubled-modal::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.filter-modal {
    --min-width: 750px;
    --min-height: 400px;
}

ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            overflow: auto;
            padding: 0;
        }
    }
}

.filter-modal::part(content) {
    width: 1200px;
}

ion-modal.auto-height-image {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;
    --width: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 85vh;
            max-width: 85vw;
            overflow: auto;
            padding: 0;
            background-color: #000;
        }
    }
}

@media only screen and (min-width: 768px) {
    .big-modal::part(content) {
        width: 80%;
    }
    
    .medium-modal::part(content) {
        width: 60%;
    }
    
    .fixed-modal::part(content) {
        width: 980px;
    }
}

@media only screen and (min-height: 768px) {
    .big-modal::part(content) {
        height: 80%;
    }
    
    .medium-modal::part(content) {
        height: 70%;
    }
    
    .fixed-modal::part(content) {
        height: 70%;
    }
    
    .tall-modal::part(content) {
        height: 85%;
    }
}

.alertcenteredbtns .alert-button-inner.sc-ion-alert-md {
    text-align: left !important;
    justify-content: flex-start;
}

// TABLE LAYOUT
table {
    background: #F4F5F8;
    border-collapse:separate;
    border:solid #F4F5F8 1px;
    border-radius: 5px;
    -moz-border-radius:5px;
    padding: 0;
}

thead {
    text-align: left;
}

th {
    padding: 12px 10px;
    font-size: 12px;
    background: #e4eaf1;
    text-align: left;
}

th:first-child {
    border-top-left-radius: 5px;
}

th:last-child {
    border-top-right-radius: 5px;
}

td {
    padding: 15px 10px;
    border-top: 1px solid #e4eaf1;
}

tr:nth-child(2n) {
    background-color: #fbfbfb;
}

tr:hover {
    background-color: #f0f0ff;
}

tr.pointer {
    transition: all 0.2s ease;
}

tr.pointer:hover {
    background-color: #3880FF;
    color: #fff;
}

table.condensed th {
    padding: 8px;
}

table.condensed td {
    padding: 8px;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}